<template>
  <div class="GeneralMeetingsTable">
    <h4 class="table-header">Bolagsstämmor</h4>
    <p v-if="instructions.length">Ta bort inaktuella bolagsstämmor, genom att klicka på krysset</p>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead class="has-background">
        <tr>
          <th class="text-left">Skapad</th>
          <th class="text-left">Senast ändrad</th>
          <th class="text-left">Namn</th>
          <th class="text-left">Hantera</th>
          <th v-if="canEdit" class="text-center narrow">Ta bort</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <tr v-for="instruction in instructions" :key="instruction.id">
          <td class="text-left v-mid">{{ formatDay(instruction.settleDate) }}</td>
          <td class="text-left v-mid">{{ formatDate(instruction.updatedAt) }}</td>
          <td class="text-left v-mid">{{ instruction.generalMeetingParticipation.name }}</td>

          <td class="text-left v-mid">
            <a-btn-link
              :to="{
                path: `${instruction.entity.baseUrl}/bolagsstamma/${instruction.id}`,
              }"
              >Ändra</a-btn-link
            >
          </td>
          <td class="text-center v-mid" v-if="canEdit">
            <q-btn
              class="delete"
              flat
              round
              dense
              :disable="!instruction.isDeleteable"
              icon="svguse:/svg-icons.svg#icon-closedot"
              @click="deleteInstruction(instruction)"
              :class="{ inactive: !instruction.isDeleteable }"
            />
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Instruction } from '@/models';
import { formatDate } from '@shared/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import RemoveInstructionModal from '@/views/editInstructionModal/RemoveInstructionModal.vue';
import { store } from '@shared/web/store';

@Component({
  components: {},
  emits: ['destroy'],
})
export default class GeneralMeetingsTable extends Vue {
  @Prop() instructions: Array<Instruction>;

  formatDate(date) {
    return formatDate(date, 'yyyy-MM-dd, HH:mm');
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  deleteInstruction(instruction) {
    this.$q
      .dialog({
        component: RemoveInstructionModal,
        componentProps: {
          value: instruction,
          modalName: 'bolagsstämma',
        },
      })
      .onOk(() => {
        this.$emit('destroy', instruction);
      });
  }
}
</script>
<style lang="scss">
.GeneralMeetingsTable {
  max-width: 100%;
  .q-table {
    td {
      .nationalid-small {
        display: block;
      }
    }
  }
}
</style>
