<template>
  <div class="ShareRegisterPDF" v-if="ready">
    <div class="page-break">
      <CoverPagePDF
        :title="title"
        :subtitle="shareRegister.entity.viewName"
        :shareRegister="shareRegister"
        :category="pdfCategory"
      />
      <ShareRegisterSummary :shareRegister="shareRegister" :category="pdfCategory" :pdfType="pdfType" />
    </div>
    <div>
      <OwnerSummaryPDF :shareRegister="shareRegister" :category="pdfCategory" :settleDate="settleDate" />
      <div class="slutnoter" v-if="isShareRegisterPDF">
        <h1>Noter</h1>
        <ol>
          <li>
            <a name="slutnot-1" />
            <em>Kapitalandel</em>: Finns olika aktieslag rekommenderas att man kontrollerar bolagsordningen som visar
            hur vinster, utdelningar etc fördelas mellan aktieslagen. Kapitalandelen som visas, avser andelen av
            bolagets bundna aktiekapital
          </li>
        </ol>
      </div>
    </div>
    <template v-for="{ holder, positions } in positionGrouping" :key="holder.id">
      <component :is="getPdfRenderComponent(holder)" :instrument="holder" :shareRegister="shareRegister" />
      <template v-for="(position, index) in positions" :key="position.id">
        <component
          :is="getPositionRenderComponent(position)"
          :position="position"
          :shareRegister="shareRegister"
          :issuerInfo="issuerInfo"
          :index="index + 1"
          :isComplete="isComplete"
        />
      </template>
    </template>
  </div>
</template>
<script lang="ts">
import { setToken } from '@/authentication';
import { Component, Vue, Watch, Prop } from 'vue-facing-decorator';
import { ShareRegister, IssuerInfo, InstrumentCategory } from '@/models';
import { PositionGroup } from '@shared/shareRegister/utils';
import { getInstrumentPositionGroups } from '@shared//shareRegister/utils';
import { findIssuerInfo } from '@shared/web/models/entity/issuerInfoService';
import ShareRegisterSummary from './ShareRegisterSummary.vue';

import CoverPagePDF from './CoverPagePDF.vue';
import OwnerSummaryPDF from './OwnerSummaryPDF.vue';
import InstrumentShaPDF from './InstrumentShaPDF.vue';
import InstrumentRightsPDF from './InstrumentRightsPDF.vue';
import InstrumentDebtPDF from './InstrumentDebtPDF.vue';
import PositionDebtPDF from './PositionDebtPDF.vue';
import PositionRightsPDF from './PositionRightsPDF.vue';
import PositionSharePDF from './PositionSharePDF.vue';
import { PDFType, PDFCategory } from '@shared/utils/pdf';
import {
  findShareRegisterByEntityIdForPDF,
  makeShareRegisterValidation,
} from '@shared/web/models/shareRegister/shareRegisterService';
import { IInstrument } from '@shared/models/modelTypes';

@Component({
  components: {
    CoverPagePDF,
    ShareRegisterSummary,
    InstrumentShaPDF,
    InstrumentRightsPDF,
    PositionDebtPDF,
    PositionRightsPDF,
    PositionSharePDF,
    OwnerSummaryPDF,
  },
})
export default class ShareRegisterPDF extends Vue {
  @Prop() pdfType: PDFType;
  @Prop() pdfCategory: PDFCategory;
  @Prop({ default: '' }) entity: string;
  @Prop({ default: '' }) custodian: string;
  @Prop() owner: string;
  @Prop({ default: '' }) instrument: string;
  @Prop({ default: '' }) settleDate: string;
  @Prop({ default: '' }) token: string;

  shareRegister: ShareRegister = null;
  issuerInfo: IssuerInfo = null;

  @Watch('entity')
  onIdChange(entity) {
    if (entity) {
      this.load();
    }
  }

  getPdfRenderComponent(holder) {
    switch (holder.category) {
      case InstrumentCategory.SHA:
        return InstrumentShaPDF;
      case InstrumentCategory.RIGHTS:
        return InstrumentRightsPDF;
      case InstrumentCategory.DEBT:
        return InstrumentDebtPDF;
    }
  }

  getPositionRenderComponent(position) {
    switch (position.instrument.category) {
      case InstrumentCategory.DEBT:
      case InstrumentCategory.SHAREHOLDER_CONTRIBUTION:
        return PositionDebtPDF;
      case InstrumentCategory.RIGHTS:
        return PositionRightsPDF;
      case InstrumentCategory.SHA:
      default:
        return PositionSharePDF;
    }
  }

  get isShareRegisterPDF() {
    return this.pdfCategory === PDFCategory.SHA;
  }

  get title() {
    switch (this.pdfCategory) {
      case PDFCategory.SHA:
        return 'Aktiebok';
      case PDFCategory.RIGHTS:
        return 'Optionsbok';
      case PDFCategory.DEBT:
        return 'Skuldbok';
      default:
        return 'Innehav';
    }
  }

  get isComplete() {
    return this.pdfType !== PDFType.legal;
  }

  get ready() {
    return this.shareRegister != null && this.issuerInfo != null;
  }

  get shareRegisterValidation() {
    return makeShareRegisterValidation(this.shareRegister);
  }

  get positionGrouping(): PositionGroup<IInstrument>[] {
    return getInstrumentPositionGroups(
      this.shareRegister.positions,
      this.shareRegister.instruments,
      'shareNumberFrom',
    ).filter(group => group.positions.length > 0);
  }

  mounted() {
    if (this.token) {
      setToken(this.token);
    }

    this.load();
  }

  async load() {
    if (!this.entity) {
      throw new Error('Entity not defined');
    }
    this.shareRegister = await findShareRegisterByEntityIdForPDF({
      entity: this.entity,
      settleDate: this.settleDate,
      ...(this.owner ? { owner: this.owner } : {}),
      ...(this.pdfCategory ? { category: this.pdfCategory } : {}),
      ...(this.instrument ? { instrument: this.instrument } : {}),
      ...(this.settleDate ? { settleDate: this.settleDate } : {}),
      ...(this.custodian ? { custodian: this.custodian } : {}),
    });
    this.issuerInfo = await findIssuerInfo(this.shareRegister.entity);
  }
}
</script>
<style lang="scss">
@media print {
  .MessageBanner {
    display: none;
  }
}

.ShareRegisterPDF {
  padding: 0 15px;
  html,
  body,
  .q-pa-md {
    height: 100%;
    width: 100%;
  }

  .large {
    font-size: 97px;
    font-weight: 200;
    height: 72px;
    letter-spacing: 1px;
    color: #2d612c;
  }
  .medium {
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .stock-title {
    font-size: 40px;
    font-weight: 200;
    line-height: 40px;
    color: #2d612c;
  }

  .panel-header,
  .large,
  .medium {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .panel {
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    &.PositionSectionPDF {
      page-break-inside: avoid;
    }
    &.InstrumentPDF {
      page-break-before: always;
    }

    .logo {
      height: 80px;
      width: 140px;
      svg {
        height: 100%;
        width: 64%;
      }
      svg:first-child {
        width: 32%;
        padding-right: 10px;
      }
    }
    dl {
      padding: 0;
      margin: 0;
      align-items: baseline;
      dt,
      dd {
        padding: 0;
        float: left;
        white-space: nowrap;
        margin: 2px 0;
        text-align: left;
      }
      dt {
        font-weight: bold;
      }
    }

    .column-list {
      width: 100%;
      overflow: hidden;
      page-break-inside: avoid;
      font-size: 16px;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      dl {
        dt,
        dd {
          width: 100%;
          word-break: normal;
          white-space: normal;
        }
      }
    }

    fieldset.panel-main {
      border-style: solid none none none;
      border-color: #2d612c;
      padding: 20px 0;
      margin-top: 20px;

      legend {
        padding: 0 10px;
        color: #2d612c;
        font-size: 18px;
        page-break-after: avoid;
      }

      h3 {
        font-weight: normal;
        font-size: 22px;
        page-break-after: avoid;

        span {
          margin-left: 10px;
          font-size: 16px;
        }
      }
    }

    table.boxes {
      width: calc(100% + 20px);
      border-spacing: 10px;
      margin: 0;
      border-collapse: separate;
      margin: 5px -10px 10px;
      page-break-inside: avoid;

      td {
        border: 2px solid lightgray;
        height: 100px;
        text-align: center;
        vertical-align: top;
        padding: 0;
        font-size: 18px;
        height: 50px;
        line-height: 32px;

        hr {
          border: 0;
          padding: 0;
          margin: 0;
          height: 1px;
          background: lightgray;
        }
        strong {
          font-size: 14px;
          line-height: 22px;
          overflow: hidden;
          display: block;
          padding-top: 2px;
        }
      }
      &.primary {
        td {
          border-color: #2d612c;
          hr {
            background: #2d612c;
          }
          strong {
            color: #2d612c;
          }
        }
      }
    }

    table.prev-owners {
      margin: 40px 0;
      overflow: hidden;
      border-collapse: collapse;
      border-color: lightgray;

      caption {
        text-align: left;
        font-weight: bold;
        font-size: 16px;
        white-space: nowrap;
      }
      tr {
        border-style: dotted none;
        border-width: 1px;
      }
      th,
      td {
        text-align: left;
        padding: 5px 0;
      }
    }

    .separator {
      border: 0;
      height: 2px;
      background: #2d612c;
      margin: 10px auto;
      &.short {
        width: 49%;
      }
    }

    &.page-break {
      page-break-after: always;
    }
  }

  .slutref {
    position: relative;
    sup {
      font-weight: normal;
      position: absolute;
    }
  }

  .slutnoter {
    margin-top: 4em;
    page-break-before: always;
    h1 {
      font-size: 1.6em;
      line-height: 120%;
    }
    ol li {
      list-style-type: auto;
    }
  }
}
</style>
