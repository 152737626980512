<template>
  <q-page v-if="ready" class="GeneralMeetingPage">
    <div class="row justify-between q-mb-md">
      <h1>Bolagsstämma</h1>
    </div>
    <p class="B1Small-label">Namn</p>
    <div class="row q-mb-md">
      <q-input v-model="generalMeetingParticipation.name" @change="handleChange" />
    </div>

    <q-form class="" ref="form">
      <p class="B1Small-label">Datum</p>
      <div class="gm-buttons">
        <a-input-date
          v-bind="$defs.input"
          placeholder="yyyy-mm-dd"
          @change="handleChange"
          v-model="instruction.settleDate"
          :disable="!ready"
          lazy-rules
        />
        <template v-if="isSaving || hasUnsavedChanges"> <q-spinner size="1.4em" />Sparar </template>
        <template v-else> <q-icon name="cloud_done" size="1.4em" />Sparat </template>
      </div>

      <GeneralMeetingView
        :shareRegister="shareRegister"
        :generalMeetingParticipation="generalMeetingParticipation"
        @change="handleChange"
      />
    </q-form>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Ref } from 'vue-facing-decorator';
import { Entity, Instruction, ShareRegister } from '@shared/web/models';
import { saveInstruction, findInstruction, getInstructionDefaults } from '@/models/instruction/instructionService';
import GeneralMeetingView from '@/views/generalMeeting/GeneralMeetingView.vue';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { InstrumentCategory, TransactionType } from '@shared/models/types';
import { looksLikeId } from '@shared/utils';
import { debounce } from 'lodash';
import { findShareRegister } from '@shared/web/models/shareRegister/shareRegisterService';
@Component({
  components: {
    GeneralMeetingView,
  },
})
export default class GeneralMeetingPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;
  entity: Entity = null;
  debouncedSave = null;
  isSaving = false;
  hasUnsavedChanges = false;

  @Ref() readonly form;

  async created() {
    this.debouncedSave = debounce(async () => {
      await this.save();
    }, 1000);

    const { id } = this.$route.params;
    const { transactionType } = this.$route.meta;

    this.entity = await findEntityWithRouteParams(this.$route.params);

    const { instruction } = this.isNew
      ? await getInstructionDefaults({ type: transactionType as TransactionType, entity: this.entity.id })
      : await findInstruction(id);

    const shareRegister = await findShareRegister({
      entity: this.entity,
      settleDate: instruction.settleDate,
      instrumentCategory: InstrumentCategory.SHA,
    });

    Object.assign(this, { shareRegister, instruction });
  }

  async save() {
    this.isSaving = true;
    const { changes, model: savedInstruction } = await saveInstruction(this.instruction);

    if (!this.instruction.id) {
      window.history.pushState({}, '', savedInstruction.webUrl);
    }

    this.instruction = savedInstruction;

    if (changes.settleDate != null) {
      await this.loadShareRegister();
    }

    this.hasUnsavedChanges = false;
    this.isSaving = false;
  }

  async handleChange() {
    this.hasUnsavedChanges = true;
    this.debouncedSave();
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  get isEditable() {
    return this.isNew || this.instruction.isEditable;
  }

  get isNew() {
    return !looksLikeId(this.$route.params.id);
  }

  get generalMeetingParticipation() {
    return this.instruction.generalMeetingParticipation;
  }
  async loadShareRegister() {
    this.shareRegister = await findShareRegister({
      entity: this.entity,
      settleDate: this.instruction.settleDate,
      instrumentCategory: InstrumentCategory.SHA,
    });
  }
}
</script>
<style lang="scss">
.GeneralMeetingPage {
  .gm-buttons {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-bottom: 2.5rem;

    label {
      padding: 0;
    }
  }
  .warning-text {
    height: 72px;
    background-color: white;
    p {
      line-height: 72px;
    }
  }
}
</style>
