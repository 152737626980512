<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin RemoveInstructionModal">
      <p class="p2" v-html="bodyText"></p>
      <q-btn label="Ta bort" :loading="loading" v-bind="$defs.btn" @click="removeTarget" />
      <q-btn flat class="cancelBtn" @click="hide" label="Avbryt" />
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DialogComponent, FormComponent } from '@shared/mixins';
import { destroyInstruction } from '@/models/instruction/instructionService';
import { Instruction } from '@/models';

@Component({
  mixins: [DialogComponent, FormComponent],
})
export default class RemoveInstructionModal extends Vue {
  @Prop() value: Instruction;
  @Prop() modalName: string;

  async removeTarget() {
    await destroyInstruction(this.value);
    this.done();
  }

  get bodyText() {
    switch (this.modalName) {
      case 'bolagsstämma':
        return `Är du säker på att du vill ta bort: <br /> <strong>${this.value.generalMeetingParticipation.name}</strong>?`;
      default:
        return `Vill du ta bort utkastet av ${this.modalName}?`;
    }
  }
}
</script>
<style lang="scss">
.RemoveInstructionModal {
  width: 347px;
  background-color: white;
  padding: 20px 20px 5px;
  .q-btn {
    font-size: 14px;
    width: 100%;
    padding: 8px 0;
  }
  .cancelBtn {
    margin-top: 10px;
    font-size: 14px;
    color: $primary;
  }
  .p2 {
    padding: 12px 0 24px;
    font-size: 15px;
    height: 90px;
  }
}
</style>
