import SearchQuery from '@shared/web/models/SearchQuery';

import { Entity, Instrument } from '@shared/web/models';
import { InstructionState, InstructionMainType } from '@shared/models/types';

export default class InstructionSearchQuery extends SearchQuery {
  constructor(attr) {
    super(attr);
    Object.assign(this, attr);
  }
  entity?: Entity = null;
  instrument?: Instrument = null;
  state: InstructionState = null;
  mainType: InstructionMainType = InstructionMainType.TRADE_EVENT;
  dateType: string = null;
  from: Date = null;
  to: Date = null;
  isEmpty() {
    return this.entity == null && this.instrument == null;
  }

  static define = () => ({
    entity: {
      model: Entity,
    },
    instrument: {
      model: Instrument,
    },
    from: {
      type: 'date',
    },
    to: {
      type: 'date',
    },
  });
}
