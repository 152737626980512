export enum InstructionState {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  REVIEW = 'REVIEW',
  REQUEST_CHANGE = 'REQUEST_CHANGE',
  EXECUTED = 'EXECUTED',
  EXECUTED_INTERIM = 'EXECUTED_INTERIM',
  EXECUTED_WAITING = 'EXECUTED_WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  CORRECTED = 'CORRECTED',
  REVERTED = 'REVERTED',
}

export enum OwnershipState {
  REVIEW = 'REVIEW',
  CONFIRMED = 'CONFIRMED',
  CLOSED = 'CLOSED',
}

export enum InstrumentCategory {
  SHA = 'SHA',
  RIGHTS = 'RIGHTS',
  DEBT = 'DEBT',
  SHAREHOLDER_CONTRIBUTION = 'SHAREHOLDER_CONTRIBUTION',
}

export enum TransactionType {
  SHAREREGISTER_CREATED = 'SHAREREGISTER_CREATED',
  SHAREREGISTER_CLOSED = 'SHAREREGISTER_CLOSED',
  INSTRUMENT_CLOSED = 'INSTRUMENT_CLOSED',
  TRADE = 'TRADE',
  GIFT = 'GIFT',
  SECURITIES_DIVIDEND = 'SECURITIES_DIVIDEND',
  INHERITED = 'INHERITED',
  INSURANCE_REPURCHASE = 'INSURANCE_REPURCHASE',
  INSURANCE_PURCHASE = 'INSURANCE_PURCHASE',
  RIGHTS_ISSUE = 'RIGHTS_ISSUE',
  ISSUE_SHARE = 'ISSUE_SHARE',
  ISSUE_WARRANT = 'ISSUE_WARRANT',
  EXERCISE_WARRANT = 'EXERCISE_WARRANT',
  ISSUE_CONVERTIBLE = 'ISSUE_CONVERTIBLE',
  ISSUE_BOND = 'ISSUE_BOND',
  EXERCISE_CONVERTIBLE = 'EXERCISE_CONVERTIBLE',
  EXERCISE_BOND = 'EXERCISE_BOND',
  SPLIT = 'SPLIT',
  SETTLED = 'SETTLED',
  EXPIRED = 'EXPIRED',
  PLEDGE = 'PLEDGE',
  EXERCISE_PLEDGE = 'EXERCISE_PLEDGE',
  KAPSURE_ISSUER_CREATED = 'KAPSURE_ISSUER_CREATED',
  KAPSURE_ISSUER_REMOVED = 'KAPSURE_ISSUER_REMOVED',
  KAPSURE_BOOKING = 'KAPSURE_BOOKING',
  KAPSURE_SETTLEMENT = 'KAPSURE_SETTLEMENT',
  KAPSURE_RIGHTS_ISSUE = 'KAPSURE_RIGHTS_ISSUE',
  KAPSURE_DVP_BUY = 'KAPSURE_DVP_BUY',
  KAPSURE_DVP_SELL = 'KAPSURE_DVP_SELL',
  KAPSURE_BANKRUPTCY = 'KAPSURE_BANKRUPTCY',
  ISSUE_DEBENTURE = 'ISSUE_DEBENTURE',
  EXERCISE_DEBENTURE = 'EXERCISE_DEBENTURE',
  REVERT = 'REVERT',
  CORRECTION = 'CORRECTION',
  ISSUE_SHAREHOLDER_CONTRIBUTION = 'ISSUE_SHAREHOLDER_CONTRIBUTION',
  SUPPLEMENT = 'SUPPLEMENT',
  GENERAL_MEETING = 'GENERAL_MEETING',
}

export enum InstructionMainType {
  TRADE_EVENT = 'TRADE_EVENT',
  CORPORATE_EVENT = 'CORPORATE_EVENT',
}

export enum InstrumentGroup {
  SHA = 'SHA',
  PAYSHA = 'PAYSHA',
  PREF = 'PREF',
  WARRANT = 'WARRANT',
  CONVERTIBLE = 'CONVERTIBLE',
  BOND = 'BOND',
  DEBENTURE = 'DEBENTURE',
  FUND_SHARE = 'FUND_SHARE',
  SYNTHETIC_SHARE = 'SYNTHETIC_SHARE',
  SHAREHOLDER_CONTRIBUTION = 'SHAREHOLDER_CONTRIBUTION',
}

export type ApplicationError = {
  text: string;
  key?: string;
  path?: string;
  data?: any;
};

export enum EntityType {
  investor = 'investor',
  issuer = 'issuer',
  kapsureIssuer = 'kapsureIssuer',
  insuranceCompany = 'insuranceCompany',
  participant = 'participant',
  parentCompany = 'parentCompany',
  insuranceOwner = 'insuranceOwner',
}

export enum Reservation {
  PRE_EMPTION = 'PRE_EMPTION',
  REFUSAL_CLAUSE = 'REFUSAL_CLAUSE',
  CONSENT_SUBJECT = 'CONSENT_SUBJECT',
  OTHER_CLAUSE = 'OTHER_CLAUSE',
  FOR_ALL = 'FOR_ALL',
}

export enum UserRole {
  ADMIN = 'ADMIN',
}

export enum RoleType {
  RealPrinciple = 'RealPrinciple',
  AlternativePrinciple = 'AlternativePrinciple',
  AuthorizedSignatory = 'AuthorizedSignatory',
  InvestorManager = 'InvestorManager',
  CEO = 'CEO',
  BoardMember = 'BoardMember',
  Accountant = 'Accountant',
  Chairman = 'Chairman',
}

export enum EntityAdminRole {
  AuthorizedSignatory = 'AuthorizedSignatory',
  Accountant = 'Accountant',
  CustodianAdmin = 'CustodianAdmin',
  Unspecified = 'Unspecifed',
}

export enum ActivityType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export enum EntityConnectionType {
  AsParticipant = 'AsParticipant',
  AsInsuranceCompany = 'AsInsuranceCompany',
}

export enum Products {
  KAPCLEAR = 'KAPCLEAR',
  KAPSURE = 'KAPSURE',
}

export enum KapsureCounterpart {
  Pareto = 'Pareto',
  Monitor = 'Monitor',
  Pepins = 'Pepins',
  SV_Ventures = 'SV_Ventures',
  Person = 'Person',
  Company = 'Company',
  Other = 'Other',
}

export enum ValuationMethod {
  DCF = 'DCF',
  TRANSACTION = 'Transaktion',
  OPTION = 'Option',
  PEER = 'Peer',
  PREF = 'Preferensaktie',
  SUBSTANCE = 'Substans',
  CONVERTIBLE = 'Konvertibel',
  RIGHTS_ISSUE = 'Nyemission',
  BOND = 'Obligation',
  KAPSURE_BANKRUPTCY = 'Konkurs',
}

export enum Quarter {
  ONE = 'Q1',
  TWO = 'Q2',
  THREE = 'Q3',
  FOUR = 'Q4',
}

export type InvestmentOffer = {
  textMessage: string;
  email: string;
  phone: string;
  firstName: string;
  surName: string;
  offerCompanyName: string;
  orgNr: string;
  companyName: string;
};

export type MergeItems = {
  targetedId: string;
  selectedId: string;
};
