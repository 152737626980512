<template>
  <q-page v-if="ready" class="GeneralMeetingsPage">
    <h1>Bolagsstämma</h1>
    <q-btn v-if="canEdit" v-bind="$defs.btn" type="button" @click="newGeneralMeeting">Ny bolagsstämma</q-btn>
    <q-separator />
    <GeneralMeetingsTable @destroy="destroy" :instructions="instructions" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { Instruction, Entity, TransactionType } from '@/models';
import GeneralMeetingsTable from '@/views/generalMeeting/GeneralMeetingsTable.vue';
import { findInstructions } from '@/models/instruction/instructionService';
import { remove } from '@/utils';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { store } from '@shared/web/store';
import { orderBy, reverse } from 'lodash';

@Component({
  components: { GeneralMeetingsTable },
})
export default class GeneralMeetingsPage extends Vue {
  instructions: Array<Instruction> = null;
  entity: Entity = null;

  async created() {
    const entity = await findEntityWithRouteParams(this.$route.params);
    const instructions = reverse(
      orderBy(await findInstructions({ entity, type: TransactionType.GENERAL_MEETING }), 'updatedAt'),
    );

    Object.assign(this, { instructions, entity });
  }

  get ready() {
    return this.instructions != null;
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  destroy(instruction) {
    remove(this.instructions, instruction);
  }

  async newGeneralMeeting() {
    await this.$router.push({ path: `${this.entity.baseUrl}/bolagsstamma/nytt` });
  }
}
</script>
