import { Vue, Component, Ref } from 'vue-facing-decorator';

type Dialog = {
  show: Function;
  hide: Function;
};

@Component({
  emits: ['ok', 'hide'],
})
export default class DialogComponent extends Vue {
  @Ref() readonly dialog!: Dialog;
  show() {
    this.dialog.show();
  }

  hide() {
    this.dialog.hide();
  }

  cancel() {
    this.$emit('hide');
  }
  done(val?) {
    this.$emit('ok', val);
    this.hide();
  }
}
