import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import Login from '@/views/Login.vue';
import Terms from '@/views/terms/Terms.vue';
import SelectEntityPage from '@/views/selectEntity/SelectEntityPage.vue';
import SelectEntityTypePage from '@/views/selectEntity/SelectEntityTypePage.vue';
import RegisterEntity from '@/views/selectEntity/RegisterEntity.vue';
import CreateShareRegisterPage from '@/views/shareRegister/CreateShareRegisterPage.vue';
import SubmitShareRegisterPage from '@/views/shareRegister/SubmitShareRegisterPage.vue';
import CheckShareRegisterPage from '@/views/shareRegister/CheckShareRegisterPage.vue';
import ShareRegisterPage from '@/views/shareRegister/ShareRegisterPage.vue';
import RightsRegisterPage from '@/views/rightsRegister/RightsRegisterPage.vue';
import DebtsRegisterPage from '@/views/debtsRegister/DebtsRegisterPage.vue';
import DocumentShareRegisterPage from '@/views/shareRegister/DocumentShareRegisterPage.vue';
import TradeEventsPage from '@/views/tradeEvent/TradeEventsPage.vue';
import TradeEventPage from '@/views/tradeEvent/TradeEventPage.vue';
import DocumentTradeEventPage from '@/views/tradeEvent/DocumentTradeEventPage.vue';
import CheckTradeEventPage from '@/views/tradeEvent/CheckTradeEventPage.vue';
import SubmittedTradeEventPage from '@/views/tradeEvent/SubmittedTradeEventPage.vue';
import RightsIssuesPage from '@/views/rightsIssue/RightsIssuesPage.vue';
import RightsIssuePage from '@/views/rightsIssue/RightsIssuePage.vue';
import DocumentRightsIssuePage from '@/views/rightsIssue/DocumentRightsIssuePage.vue';
import CheckRightsIssuePage from '@/views/rightsIssue/CheckRightsIssuePage.vue';
import SubmittedRightsIssuePage from '@/views/rightsIssue/SubmittedRightsIssuePage.vue';

import SplitsPage from '@/views/split/SplitsPage.vue';
import SplitPage from '@/views/split/SplitPage.vue';
import DocumentSplitPage from '@/views/split/DocumentSplitPage.vue';
import CheckSplitPage from '@/views/split/CheckSplitPage.vue';
import SubmittedSplitPage from '@/views/split/SubmittedSplitPage.vue';

import ShareholderContributionsPage from '@/views/shareholderContributions/ShareholderContributionsPage.vue';

import IssueWarrantPage from '@/views/issueWarrant/IssueWarrantPage.vue';
import InstructionSubmittedPage from '@/views/instruction/SubmittedInstructionPage.vue';

import EntityAdminPage from '@/views/entityAdmin/EntityAdminPage.vue';
import CreateEntityAdminPage from '@/views/entityAdmin/CreateEntityAdminPage.vue';
import RolesAdminPage from '@shared/views/rolesAdmin/RolesAdminPage.vue';
import DashboardPage from '@/views/dashboard/DashboardPage.vue';
import TransactionsPage from '@/views/transactions/TransactionsPage.vue';
import InvestorBookPage from '@/views/investorBook/InvestorBookPage.vue';
import UserInvestmentsPage from '@/views/userInvestments/UserInvestmentsPage.vue';
import UserInvestmentOffersPage from '@/views/userInvestmentOffers/UserInvestmentOffersPage.vue';
import UserInvestmentOfferDetails from '@/views/userInvestmentOffers/UserInvestmentOfferView.vue';
import UserTransactionsPage from '@/views/userTransactions/UserTransactionsPage.vue';
import UserSettingsPage from '@/views/userSettings/UserSettingsPage.vue';
import UserKapnetConsentPage from '@/views/userKapnetConsent/UserKapnetConsentPage.vue';

import CustodianHoldings from '@/views/custodian/CustodianHoldings.vue';
import InsurerPositionsPage from '@/views/insurer/InsurerPositionsPage.vue';

import SavePDF from '@/views/savePdf/SavePDF.vue';
import SecuritiesPage from '@/views/securities/SecuritiesPage.vue';
import { findProfile } from '@/models/profileService';
import { setActiveEntity } from '@/models/entity/entityService';

import { Context, Intent, Layout } from './types';

import CheckInstructionPage from '@/views/instruction/CheckInstructionPage.vue';
import InstructionDocumentsPage from '@/views/instruction/InstructionDocumentsPage.vue';
import { getEnv } from '@/utils';
import IssueSecurityPage from '@/views/securities/IssueSecurityPage.vue';
import UserEngagementsPage from '@/views/userInvestments/UserEngagementsPage.vue';
import { InstructionState, TransactionType } from '@shared/models/types';
import DocumentsLibraryPage from '@/views/documents/DocumentLibraryPage.vue';
import GeneralMeetingsPage from '@/views/generalMeeting/GeneralMeetingsPage.vue';
import GeneralMeetingPage from '@/views/generalMeeting/GeneralMeetingPage.vue';

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Login,
    name: 'Login',
    meta: {
      intent: Intent.LOGIN,
      title: 'Logga in',
      public: true,
      layout: Layout.ONBOARDING,
    },
  },
  {
    path: '/registrera',
    component: Login,
    name: 'Register',
    meta: {
      intent: Intent.REGISTER,
      title: 'Bli kund',
      public: true,
      layout: Layout.ONBOARDING,
    },
  },
  {
    path: '/valjbolag',
    component: SelectEntityPage,
  },
  {
    path: '/:countryCode/:entity/valj-typ',
    component: SelectEntityTypePage,
  },
  {
    path: '/registrerabolag/:nationalId?',
    component: RegisterEntity,
    meta: {
      layout: Layout.ONBOARDING,
    },
  },
  {
    path: '/:countryCode/:entity/skapa-aktiebok',
    component: CreateShareRegisterPage,
    meta: {
      pledgeEditable: true,
    },
  },
  {
    path: '/:countryCode/:entity/skapa-aktiebok/dokument',
    component: DocumentShareRegisterPage,
  },
  {
    path: '/:countryCode/:entity/skapa-aktiebok/granska',
    component: CheckShareRegisterPage,
  },
  {
    path: '/:countryCode/:entity/skapa-aktiebok/skickain',
    component: SubmitShareRegisterPage,
  },
  {
    path: '/:countryCode/:entity/aktiebok',
    component: ShareRegisterPage,
  },
  {
    path: '/:countryCode/:entity/bolagsstamma',
    component: GeneralMeetingsPage,
  },
  {
    path: '/:countryCode/:entity/bolagsstamma/:id',
    component: GeneralMeetingPage,
    meta: { transactionType: TransactionType.GENERAL_MEETING },
  },
  {
    path: '/:countryCode/:entity/optionsbok',
    component: RightsRegisterPage,
  },
  {
    path: '/:countryCode/:entity/skuldbok',
    component: DebtsRegisterPage,
  },
  {
    path: '/:countryCode/:entity/agarbyte',
    component: TradeEventsPage,
  },
  {
    path: '/:countryCode/:entity/agarbyte/:id',
    component: TradeEventPage,
    meta: { transactionType: TransactionType.TRADE },
  },
  {
    path: '/:countryCode/:entity/agarbyte/:id/dokument',
    component: DocumentTradeEventPage,
    meta: { transactionType: TransactionType.TRADE },
  },
  {
    path: '/:countryCode/:entity/agarbyte/:id/granska',
    component: CheckTradeEventPage,
    meta: { transactionType: TransactionType.TRADE },
  },
  {
    path: '/:countryCode/:entity/agarbyte/:id/skickain',
    component: SubmittedTradeEventPage,
    meta: { transactionType: TransactionType.TRADE },
  },
  {
    path: '/:countryCode/:entity/vardepapper',
    component: SecuritiesPage,
  },
  {
    path: '/:countryCode/:entity/optionsprogram/:id',
    component: IssueWarrantPage,
    meta: { transactionType: TransactionType.ISSUE_WARRANT },
  },
  {
    path: '/:countryCode/:entity/optionsprogram/:id/dokument',
    component: InstructionDocumentsPage,
    meta: { transactionType: TransactionType.ISSUE_WARRANT },
  },
  {
    path: '/:countryCode/:entity/optionsprogram/:id/granska',
    component: CheckInstructionPage,
    meta: { transactionType: TransactionType.ISSUE_WARRANT },
  },
  {
    path: '/:countryCode/:entity/optionsprogram/:id/skickain',
    component: InstructionSubmittedPage,
    meta: { transactionType: TransactionType.ISSUE_WARRANT },
  },
  {
    path: '/:countryCode/:entity/konvertibler/:id',
    component: IssueSecurityPage,
    meta: { transactionType: TransactionType.ISSUE_CONVERTIBLE },
  },
  {
    path: '/:countryCode/:entity/konvertibler/:id/dokument',
    component: InstructionDocumentsPage,
    meta: { transactionType: TransactionType.ISSUE_CONVERTIBLE },
  },
  {
    path: '/:countryCode/:entity/konvertibler/:id/granska',
    component: CheckInstructionPage,
    meta: { transactionType: TransactionType.ISSUE_CONVERTIBLE },
  },
  {
    path: '/:countryCode/:entity/konvertibler/:id/skickain',
    component: InstructionSubmittedPage,
    meta: { transactionType: TransactionType.ISSUE_CONVERTIBLE },
  },
  {
    path: '/:countryCode/:entity/obligationer/:id',
    component: IssueSecurityPage,
    meta: { transactionType: TransactionType.ISSUE_BOND },
  },
  {
    path: '/:countryCode/:entity/obligationer/:id/dokument',
    component: InstructionDocumentsPage,
    meta: { transactionType: TransactionType.ISSUE_BOND },
  },
  {
    path: '/:countryCode/:entity/obligationer/:id/granska',
    component: CheckInstructionPage,
    meta: { transactionType: TransactionType.ISSUE_BOND },
  },
  {
    path: '/:countryCode/:entity/obligationer/:id/skickain',
    component: InstructionSubmittedPage,
    meta: { transactionType: TransactionType.ISSUE_BOND },
  },
  {
    path: '/:countryCode/:entity/komplettering/:id',
    component: IssueSecurityPage,
    meta: { transactionType: TransactionType.SUPPLEMENT },
  },
  {
    path: '/:countryCode/:entity/komplettering/:id/dokument',
    component: InstructionDocumentsPage,
    meta: { transactionType: TransactionType.SUPPLEMENT },
  },
  {
    path: '/:countryCode/:entity/komplettering/:id/granska',
    component: CheckInstructionPage,
    meta: { transactionType: TransactionType.SUPPLEMENT },
  },
  {
    path: '/:countryCode/:entity/komplettering/:id/skickain',
    component: InstructionSubmittedPage,
    meta: { transactionType: TransactionType.SUPPLEMENT },
  },
  {
    name: 'IssueSecurityPage',
    path: '/:countryCode/:entity/vinstandelslan/:id',
    component: IssueSecurityPage,
    meta: { transactionType: TransactionType.ISSUE_DEBENTURE },
  },
  {
    path: '/:countryCode/:entity/vinstandelslan/:id/dokument',
    component: InstructionDocumentsPage,
    meta: { transactionType: TransactionType.ISSUE_DEBENTURE },
  },
  {
    path: '/:countryCode/:entity/vinstandelslan/:id/granska',
    component: CheckInstructionPage,
    meta: { transactionType: TransactionType.ISSUE_DEBENTURE },
  },
  {
    path: '/:countryCode/:entity/vinstandelslan/:id/skickain',
    component: InstructionSubmittedPage,
    meta: { transactionType: TransactionType.ISSUE_DEBENTURE },
  },
  {
    path: '/:countryCode/:entity/aktieagartillskott',
    component: ShareholderContributionsPage,
  },
  {
    path: '/:countryCode/:entity/aktieagartillskott/:id',
    component: IssueSecurityPage,
    meta: { transactionType: TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION },
  },
  {
    path: '/:countryCode/:entity/aktieagartillskott/:id/dokument',
    component: InstructionDocumentsPage,
    meta: { transactionType: TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION },
  },
  {
    path: '/:countryCode/:entity/aktieagartillskott/:id/granska',
    component: CheckInstructionPage,
    meta: { transactionType: TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION },
  },
  {
    path: '/:countryCode/:entity/aktieagartillskott/:id/skickain',
    component: InstructionSubmittedPage,
    meta: { transactionType: TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION },
  },
  {
    path: '/:countryCode/:entity/nyemission',
    component: RightsIssuesPage,
  },
  {
    path: '/:countryCode/:entity/nyemission/:id',
    component: RightsIssuePage,
  },
  {
    path: '/:countryCode/:entity/nyemission/:id/dokument',
    component: DocumentRightsIssuePage,
  },
  {
    path: '/:countryCode/:entity/nyemission/:id/granska',
    component: CheckRightsIssuePage,
  },
  {
    path: '/:countryCode/:entity/nyemission/:id/skickain',
    component: SubmittedRightsIssuePage,
  },
  {
    path: '/:countryCode/:entity/split',
    component: SplitsPage,
  },
  {
    path: '/:countryCode/:entity/split/:id',
    component: SplitPage,
  },
  {
    path: '/:countryCode/:entity/split/:id/dokument',
    component: DocumentSplitPage,
  },
  {
    path: '/:countryCode/:entity/split/:id/granska',
    component: CheckSplitPage,
  },
  {
    path: '/:countryCode/:entity/split/:id/skickain',
    component: SubmittedSplitPage,
  },
  {
    path: '/:countryCode/:entity/admin',
    component: EntityAdminPage,
  },
  {
    path: '/:countryCode/:entity/roller',
    component: RolesAdminPage,
  },
  {
    path: '/:countryCode/:entity/admin/skapa',
    component: CreateEntityAdminPage,
  },
  {
    path: '/:countryCode/:entity/admin/editera/:id',
    component: CreateEntityAdminPage,
  },
  {
    path: '/:countryCode/:entity/dashboard',
    component: DashboardPage,
  },
  {
    path: '/:countryCode/:entity/investerarbok',
    component: InvestorBookPage,
  },
  {
    path: '/:countryCode/:entity/transaktioner',
    component: TransactionsPage,
  },
  {
    path: '/:countryCode/:entity/custodian',
    component: CustodianHoldings,
  },
  {
    path: '/:countryCode/:entity/custodian/:instrument/instrument',
    component: CustodianHoldings,
  },
  {
    path: '/:countryCode/:entity/insurer',
    component: InsurerPositionsPage,
  },
  {
    path: '/:countryCode/:entity/insurer/:instrument/instrument',
    component: InsurerPositionsPage,
  },
  {
    path: '/:countryCode/:entity/avtalsmallar',
    component: DocumentsLibraryPage,
  },
  {
    path: '/mina-innehav',
    component: UserInvestmentsPage,
    meta: {
      context: Context.USER,
      title: 'Mina innehav / Intressen',
    },
  },
  {
    path: '/investment-offer',
    name: 'Investerings_erbjudande',
    component: UserInvestmentOffersPage,
    meta: {
      context: Context.USER,
      title: 'Mina investeringserbjudanden',
    },
  },
  {
    path: '/investment-offer/:id',
    name: 'InvestmentOfferDetails',
    component: UserInvestmentOfferDetails,
    meta: {
      context: Context.USER,
      title: 'Investerings erbjudande detaljer',
    },
  },
  {
    path: '/mina-bolagsengagemang',
    component: UserEngagementsPage,
    meta: {
      context: Context.USER,
      title: 'Mina bolagsengagemang',
    },
  },
  {
    path: '/mina-transaktioner',
    component: UserTransactionsPage,
    meta: {
      context: Context.USER,
      title: 'Mina innehav',
    },
  },
  {
    path: '/mina-inst%C3%A4llningar',
    component: UserSettingsPage,
    meta: {
      context: Context.USER,
      title: 'Mina innehav',
    },
  },
  {
    path: '/mina-avtal',
    component: UserKapnetConsentPage,
    meta: {
      context: Context.USER,
      title: 'Mina innehav',
    },
  },
  {
    path: '/avtalsmallar',
    component: DocumentsLibraryPage,
    meta: {
      context: Context.USER,
      title: 'Mina innehav',
    },
  },
  {
    path: '/aktiebok-pdf/:id',
    component: SavePDF,
    meta: {
      public: true,
      layout: Layout.PDF,
    },
  },
  {
    path: '/transaktion-pdf/:id',
    component: SavePDF,
    meta: {
      public: true,
      layout: Layout.PDF,
    },
  },
];
const termsRoutes: Array<RouteRecordRaw> = [
  {
    name: 'villkor',
    path: '/villkor',
    component: Terms,
    meta: {
      layout: Layout.ONBOARDING,
    },
  },
];

// Combine routes
const routes: Array<RouteRecordRaw> = [...authRoutes, ...termsRoutes];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const originalPush = router.push;
router.push = async function push(to: any, onComplete?: (route: any) => void, onAbort?: (err: Error) => void) {
  try {
    await originalPush.call(router, to, onComplete);
  } catch (err) {
    if (onAbort) {
      onAbort(err);
    } else {
      console.warn(err.message);
    }
  }
};

router.beforeEach(async (to, from, next) => {
  try {
    const profile = await findProfile();
    if (!to.meta.public && !profile.loggedIn) {
      return next({ name: 'Login', query: { tillbakaTill: to.fullPath } });
    }

    if (isEntityPage(to)) {
      const { activeEntity, entityAdmin } = await setActiveEntity({
        countryCode: to.params.countryCode as string,
        nationalId: to.params.entity as string,
      });

      if (activeEntity.issuerData?.shareRegisterState !== InstructionState.EXECUTED && !activeEntity.termsAccepted) {
        return next({
          name: 'villkor',
          query: {
            nextUrl: to.fullPath,
            entity: activeEntity.id,
            entityAdmin: entityAdmin.id,
          },
        });
      }
    } else {
      await setActiveEntity(false);
    }

    setEnvToTitle();
    next();
  } catch (error) {
    console.error('Routing error:', error);
    next(error);
  }
});

function setEnvToTitle() {
  const env = getEnv();
  document.title = env ? `${env} | Company name` : 'Company name';
}

function isEntityPage(to: RouteLocationNormalized) {
  return matchesCountryCodeAndNationalId(to.path);
}

function matchesCountryCodeAndNationalId(url: string) {
  return /^\/[a-zA-Z]{2,3}\/[a-zA-Z0-9.\s]+/.test(url);
}

export default router;
